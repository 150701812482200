import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
export class EnvioMaterialModel {
  constructor({
    id,
    codigo = null,
    solicitante = null,
    solicitanteContratante = null,
    contratante = null,
    contatoContratante = null,
    dataSolicitacao = new Date(),
    dataAgendamento = null,
    tipoEnvio = '',
    tipoEnvioMaterial = null,
    ordemServico = null,
    nomeTransportadora = '',
    quantidadeVolumes = '',
    instrumentos = [],
    historico = [],
    situacao = '',
    anexos,
    contatoAtendimento = null,
    notaFiscal,
    pesoEstimado = null,
    qrCodes = [],
    flagAprovado
  }) {
    this.id = id;
    this.codigo = codigo;
    this.notaFiscal = notaFiscal;
    this.solicitanteId = solicitante;
    if (solicitante) this.solicitanteId = new DropdownModel(solicitante, 'apelido');
    this.solicitanteContratanteId = solicitanteContratante;
    if (solicitanteContratante) this.solicitanteContratanteId = new DropdownModel(solicitanteContratante,'apelido');
    this.contratanteId = contratante;
    if (contratante) this.contratanteId = new DropdownModel(contratante,'apelido');
    if (contatoContratante) {
      this.contatoContratanteId = new DropdownModel(contatoContratante);
    }
    this.dataAgendamento = dataAgendamento;
    this.nomeTransportadora = nomeTransportadora;
    this.quantidadeVolumesInicial = quantidadeVolumes;
    this.pesoEstimado = pesoEstimado;
    this.quantidadeVolumes = quantidadeVolumes;
    if (tipoEnvio) {
      this.tipoEnvioId = new DropdownModel(tipoEnvio);
    }

    this.anexos = anexos ?? [];
    this.anexos?.forEach((anexo) => (anexo.flagJaExistente = true));
    this.tipoEnvioMaterial = tipoEnvioMaterial;
    this.instrumentos = instrumentos;
    this.ordemServicoId = ordemServico
    if (ordemServico)
      this.ordemServicoId = new DropdownModel(ordemServico);
    if (dataSolicitacao)
      this.dataSolicitacao = dataSolicitacao
    if (historico)
      this.historico = historico
    this.situacao = situacao
    if (contatoAtendimento)
      this.contatoAtendimento = new DropdownModel(contatoAtendimento)
    this.qrCodes = qrCodes;
    this.flagAprovado = flagAprovado;
  }

  get request() {
    let retorno = _.cloneDeep(this);

    retorno.solicitanteId = this.solicitanteId?.value;
    retorno.solicitanteContratanteId = this.solicitanteContratanteId?.value ?? this.solicitanteId?.value;

    retorno.contratanteId = this.contratanteId?.value;
    if (this.tipoEnvioId.value)
      retorno.tipoEnvioId = this.tipoEnvioId?.value;
    else
      retorno.tipoEnvioId = this.tipoEnvioId;
    retorno.ordemServicoId = this.ordemServicoId?.value;

    if (this.contatoContratanteId?.value)
      retorno.contatoContratanteId = this.contatoContratanteId?.value;
    else
      retorno.contatoContratanteId = this.contatoContratanteId;

    retorno.dataAgendamento = retorno.dataAgendamento.toString().substring(0, 10)

    retorno.instrumentosIds = this.instrumentos?.map(({ id }) => id);

    retorno.anexos?.forEach(
      (anexo) => (anexo.id = !anexo.flagJaExistente ? null : anexo.id)
    );

    if(!retorno.id) {
      retorno.anexos = _.cloneDeep(retorno.anexos);
    }

    return retorno;
  }
}
