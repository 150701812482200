<template>
  <div>
    <div
      v-if="!ehVisualizar"
      class="d-flex align-center justify-space-between mb-3"
    >
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
          :disabled="ehVisualizar"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        class="mt-3"
        @click="abreModalAnexo"
        :disabled="ehVisualizar"
      >
        {{ $t('modulos.orcamentos.formulario.anexar_arquivo') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            @click="fazerDownloadArquivo(item.item)"
            :disabled="ehVisualizar"
          >
            {{ $t('geral.botoes.download') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
    <AnexoModal
      ref="modal-anexo"
      :form="form"
      @novoArquivo="novoArquivoAnexo"
    />
  </div>
</template>
<script>
import AnexoModal from './modais/AnexoModal.vue';
import helpers from '@common/utils/helpers';

export default {
  components: {
    AnexoModal,
  },
  props: {
    form: {},
    ehVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: this.form.anexos,
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nomeExtensaoArquivo',
            text: this.$t('modulos.orcamentos.tabela.nome'),
            sortable: true,
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  methods: {
    abreModalAnexo() {
      this.$refs['modal-anexo'].abrirModal();
    },
    novoArquivoAnexo(arquivo) {
      this.form.anexos.push(arquivo);
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.anexos.splice(this.form.anexos.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
    fazerDownloadArquivo: async function (item) {
      helpers.baixarBase64(item.anexo, item.nomeExtensaoArquivo);
    },
  },
};
</script>
