export class DropdownTipoEnvio {
    constructor(lista, texto = 'nome') {
      if (Array.isArray(lista))
        return lista.map((el) => {
          let novoObj = {};
          novoObj.value = el.id;
          novoObj.text = el[texto];
          novoObj.flagVisivelRoteirizacao = el.flagVisivelRoteirizacao;
          return novoObj;
        });
      return {
        value: lista.id,
        text: lista[texto],
        flagVisivelRoteirizacao: lista.flagVisivelRoteirizacao
      };
    }
  }
  