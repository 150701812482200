<template>
  <div>
    <div class="mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
      <div v-if="badges.codigo">
        <v-chip
          class="mr-1"
          small
        >
          Código: {{ badges.codigo }}
        </v-chip>
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.envio_material.formulario.abas.dados') }}
      </v-tab>
      <v-tab :disabled="!form.contratanteId || ($route.query.aprovar && !form.solicitanteId)">
        {{ $t('modulos.envio_material.formulario.abas.instrumentos') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_interno.formulario.abas.anexos') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-envio-material
          :id="id"
          ref="dados-envio-material"
          :eh-visualizar="ehVisualizar"
          :form="form"
          @validarFormulario="validarFormulario"
          @clienteWatcherToggle="clienteWatcherToggle"
        />
      </v-tab-item>
      <v-tab-item :disabled="!form.contratanteId">
        <instrumentos
          :form="form"
          :eh-visualizar="ehVisualizar"
          @validarInstrumento="validarInstrumento"
        />
      </v-tab-item>
      <v-tab-item>
        <anexos
          :form="form"
          :eh-visualizar="ehVisualizar"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <template v-if="ehVisualizar">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="voltar"
        >
          {{ $t('geral.botoes.voltar') }}
        </botao-padrao>
      </template>
      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao @click="confirmacaoSalvar">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ this.$route.query?.aprovar ? $t('geral.botoes.aprovar') : $t('geral.botoes.salvar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash';
import helpers from '@/common/utils/helpers';
// import { DropdownModel } from '@/common/models/geral/DropdownModel';
import EnvioMaterialService from '@/common/services/cadastros/EnvioMaterialService.js';
// import ParticipanteService from '@/common/services/cadastros/ParticipanteService.js';
import { EnvioMaterialModel } from '@/common/models/cadastros/EnvioMaterialModel.js';
import DadosEnvioMaterial from './components/DadosEnvioMaterial.vue';
import Instrumentos from './components/Instrumentos';
import { mapGetters } from 'vuex';
import Anexos from './components/Anexos.vue';

export default {
  components: {
    DadosEnvioMaterial,
    Instrumentos,
    Anexos
  },
  props: {
    id: { type: String, default: null },
  },
  data() {
    return {
      indiceAba: 0,
      valido: false,
      badges: { codigo: '' },
      instrumentoCadastrado: false,
      form: new EnvioMaterialModel({}),
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
      tipoColetaWatcher: null,
      ordemServicoWatcher: null,
      clienteWatcher: null,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta', 'participanteSelecionado']),
    desabilitarSalvar() {
      return !this.valido || !this.instrumentoCadastrado;
    },
    tituloFormulario() {
      if(this.$route.query?.aprovar) return this.$t('modulos.envio_material.titulos.aprovar') + ' ' + this.form.codigo;
      if (this.ehVisualizar)
        return this.$t('modulos.envio_material.titulos.visualizar') + ' ' + this.form.codigo;
      return this.id
        ? this.$t('modulos.envio_material.titulos.editar')
        : this.$t('modulos.envio_material.titulos.novo');
    },
    ehVisualizar() {
      return this.$route.name === 'envio-material-visualizar';
    },
  },
  async mounted() {
    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this,'EnvioMaterial', 'Deletar');
    }
    helpers.redirecionarSemPermissao(this,'EnvioMaterial', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    if(!this.id) this.setarWatchers();

  },
  methods: {
    setarWatchers(){
      if (!this.ordemServicoWatcher)
      this.ordemServicoWatcher = this.$watch('form.ordemServicoId', (atual) => {
        this.form.instrumentos = [];
        if(atual)
        this.$refs['dados-envio-material']?.buscaDadosOrdemServico(this.form.ordemServicoId.value)
      });
      if (!this.tipoColetaWatcher)
      this.tipoColetaWatcher = this.$watch('form.tipoEnvioMaterial', () => {
        this.form.ordemServicoId = null;
      });
      if (!this.clienteWatcher) this.definirWatcherContratante();
    },
    async buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await EnvioMaterialService.buscar(this.id)
        .then((res) => {
          this.form = new EnvioMaterialModel(res.data);
          this.badges.codigo = res.data.codigo;
          this.valido = true;
        })

        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.setarWatchers()
          this.verificarRotaAprovacao();
          this.$refs['dados-envio-material']?.buscarContato(this.form.contratanteId.value);
        });
    },
    verificarQRCodesAlteracaoQuantidade(){
      if(this.form.quantidadeVolumesInicial !== this.form.quantidadeVolumes && this.form.qrCodes.length){
        this.confirmar(this.$t('modulos.envio_material.formulario.quantidade_volumes'),
      this.$t('modulos.envio_material.aviso_qrcode_quantidade_volumes'))
        .then(() => {
          this.salvar();
        });
      }
      return this.form.quantidadeVolumesInicial !== this.form.quantidadeVolumes && this.form.qrCodes.length;
    },
    verificarRotaAprovacao(){
      if(!this.$route.query?.aprovar) return;
      this.form.solicitanteId = null;
      this.toastAlerta(this.$t('modulos.envio_material.aprovacao_solicitante'))
      this.form.instrumentos = cloneDeep(this.form.instrumentos.filter(el => el.participante.id === this.form.solicitanteContratanteId?.value));
      this.$refs['dados-envio-material'].$refs.form.validate();
    },
    confirmacaoSalvar() {
      if(this.verificarQRCodesAlteracaoQuantidade()) return;
      if (!this.validarEnvioMaterial()) return;
      if(!this.validarInstrumentosAprovacao()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validarEnvioMaterial() {
      if (this.$refs['dados-envio-material']) {
        this.valido = this.$refs['dados-envio-material'].$refs.form.validate();
        this.indiceAba = 0;
      }
      return this.valido;
    },
    validarInstrumentosAprovacao(){
      if(this.$route.query.aprovar && this.participanteLogadoConta) {
      const instrumentosSolicitanteContratante =
        this.form.instrumentos.filter(instrumento => instrumento.participante?.id === this.form.solicitanteContratanteId?.value);

      const instrumentosSolicitante =
        this.form.instrumentos.filter(instrumento => instrumento.participante?.id === this.form.solicitanteId?.value);

      if(instrumentosSolicitanteContratante.length !== instrumentosSolicitante.length){
        this.toastErro(this.$t('modulos.envio_material.aprovacao_instrumentos_invalidos'))
        this.indiceAba = 1;
        return false;
      }
    }
    return true;
    },
    verificarAprovacaoEAlteracao(){
      if(this.$route.query?.aprovar && this.participanteLogadoConta) this.form.flagAprovado = true;
      if(!this.participanteLogadoConta &&
        this.form.solicitanteContratanteId?.value !== this.form.solicitanteId?.value){
          this.form.solicitanteId = cloneDeep(this.form.solicitanteContratanteId);
          this.form.flagAprovado = false;
          this.form.instrumentos = this.form.instrumentos.filter(instrumento => instrumento.participante.id === this.form.solicitanteContratanteId?.value);
        }
    },
    salvar() {
      this.verificarAprovacaoEAlteracao();
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnvioMaterialService.salvar(this.form.request)
        .then((res) => {
          const { nome } = res.data;
          this.toastSucesso(
            this.$t('modulos.envio_material.cadastro_sucesso', { nome })
          );
          this.$router.push({ name: 'envio-material' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'envio-material' });
      });
    },
    validarFormulario(validar) {
      this.valido = validar;
    },
    validarInstrumento(validar) {
      this.instrumentoCadastrado = validar;
    },
    voltar() {
      this.$router.push({ name: 'envio-material' });
    },
    definirWatcherContratante(){
      this.clienteWatcher = this.$watch('form.contratanteId', (atual, antigo) => {
          if (isEqual(atual, antigo)) return;
          this.$refs['dados-envio-material']?.atualizarDadosContratante(atual.value);
          this.form.ordemServicoId = null;
          this.form.solicitanteId = atual;
          this.form.instrumentos = [];
          if(this.form.contatoContratanteId)
            this.form.contatoContratanteId = null;
        });
    },
    clienteWatcherToggle: function () {
      if (this.clienteWatcher) {
        this.clienteWatcher();
        this.clienteWatcher = null;
        this.definirWatcherContratante();
      } else this.definirWatcherContratante();
    },
  },
};
</script>
