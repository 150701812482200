<template lang="">
  <div class="mt-5">
    <div
      v-if="!ehVisualizar"
      class="d-flex align-center justify-space-between mb-3"
    >
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          :disabled="ehVisualizar"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <div v-if="!$route.query.aprovar" class="d-flex">
        <botao-padrao
          v-if="buscarPermissao('Instrumento', 'Inserir')"
          :disabled="ehVisualizar || !!$route.query.aprovar"
          @click="() => abreModalAdicionaInstrumento()"
        >
          {{
            $t('modulos.envio_material.instrumentos.botoes.novo_instrumento')
          }}
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          :disabled="ehVisualizar || !podeInserirInstrumentos || podeEditar"
          @click="associarDesassociarInstrumento"
        >
          {{
            $t(
              'modulos.envio_material.instrumentos.botoes.selecionar_instrumentos'
            )
          }}
        </botao-padrao>
      </div>
    </div>
    <tabela-padrao-prime-vue
      v-if="$route.query.aprovar"
      v-model="tabela.selecionados"
      :dados="
        tabela.dados.filter(
          (instrumento) =>
            instrumento.participante.id === form.solicitanteContratanteId?.value
        )
      "
      class="mt-2"
      :colunas="[tabela.colunaAprovacao, ...tabela.colunas]"
      sem-paginacao
      :mostrar-acoes="false"
      ajustar-altura-linha
      :mostrar-seletor="false"
    >
      <template v-slot:codigoAprovacao="{ slotProps }">
        <div class="mb-2">
          <input-instrumento
            v-model="buscarVModelInstrumentoAprovacao(slotProps.data.id).data"
            :placeholder="$t('geral.inputs.selecione')"
            :multiplos="false"
            com-botao-adicionar
            estilo-botao-adicionar
            retornar-objeto
            :cliente-selecionado="form.solicitanteId"
            somente-ativos
            :aprovacao-envio-material-dado="slotProps.data"
            @input="
              (instrumento) =>
                vincularInstrumentoAprovacao(instrumento, slotProps.data.id)
            "
          >
            <template #botaoAdicionar>
              <botao-padrao
                :botao-adicionar-input="true"
                :tooltip="tooltip"
                @click="abreModalAdicionaInstrumento(slotProps.data.id)"
              >
                <span style="font-size: 23px"> + </span>
              </botao-padrao>
            </template>
          </input-instrumento>
        </div>
      </template>
    </tabela-padrao-prime-vue>

    <tabela-padrao-prime-vue
      v-if="!$route.query.aprovar"
      v-model="tabela.selecionados"
      :dados="dadosTabela"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
      :mostrar-acoes="false"
      ajustar-altura-linha
      :mostrar-seletor="!ehVisualizar && !podeEditar"
    />
    <instrumento-modal
      ref="modal-instrumento"
      :participante-id="idParticipanteInstrumentos"
      :value="tabela.dados"
      somente-ativos
      @associarInstrumentos="associarInstrumentos"
    />
    <criacao-instrumento-modal
      ref="modal-criacao-instrumento"
      @novoInstrumento="associarNovoInstrumento"
    />
  </div>
</template>
<script>
import { InputInstrumento } from '@components/inputs';
import { mapGetters } from 'vuex';
import CriacaoInstrumentoModal from './modais/CriacaoInstrumentoModal.vue';
import InstrumentoModal from './modais/InstrumentoModal.vue';
import helpers from '@/common/utils/helpers';

export default {
  components: { InstrumentoModal, CriacaoInstrumentoModal, InputInstrumento },
  props: {
    form: {},
    ehVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('modulos.envio_material.instrumentos.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.tipo_instrumento'
            ),
            sortable: true,
            formatter: (v) => v.nome ?? v.text,
          },
          {
            value: 'marca',
            text: this.$t('modulos.envio_material.instrumentos.tabela.marca'),
            sortable: true,
            formatter: (v) => v.nome ?? v.text,
          },
          {
            value: 'modeloInstrumento',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.modelo_instrumento'
            ),
            sortable: true,
            formatter: (v) => v.nome ?? v.text,
          },
          {
            value: 'numeroSerie',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.numero_serie'
            ),
            sortable: true,
          },
          {
            value: 'quantidadeInstrumentoFaixas',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.qtd_faixas'
            ),
            sortable: true,
            formatter: (v) => v ?? ' - ',
          },
        ],
        colunaAprovacao: {
          value: 'codigoAprovacao',
          text: this.$t(
            'modulos.envio_material.instrumentos.tabela.codigo_aprovacao'
          ),
          sortable: true,
        },
        selecionados: [],
      },
      tooltip: this.$t('modulos.envio_material.aprovacao_criar_instrumento'),
      instrumentoClone: null,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    podeInserirInstrumentos() {
      return !!this.form.solicitanteId || !!this.form.solicitanteContratanteId;
    },
    idParticipanteInstrumentos() {
      if (this.participanteLogadoConta) {
        return this.form.solicitanteId?.value;
      }
      return (
        this.form.solicitanteContratanteId?.value ??
        this.form.solicitanteId?.value
      );
    },
    podeEditar(){
      return !this.participanteLogadoConta && this.form.tipoEnvioMaterial == 'LaboratorioCliente' && !!this.form.id
    },
    dadosTabela() {
      if (this.participanteLogadoConta) {
        return this.tabela.dados.filter(
          (instrumento) =>
            instrumento.participante.id === this.form.solicitanteId?.value
        );
      }
      return this.tabela.dados.filter(
        (instrumento) =>
          instrumento.participante.id ===
          (this.form.solicitanteContratanteId?.value ??
            this.form.solicitanteId?.value)
      );
    },
  },
  watch: {
    form: {
      handler() {
        this.tabela.dados = this.form.instrumentos;
      },
      deep: true,
    },
  },
  mounted() {
    this.tabela.dados = this.form.instrumentos;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    buscarVModelInstrumentoAprovacao(id) {
      return {
        data: this.form.instrumentos.find((el) => el.relacionamentoId === id),
      };
    },
    vincularInstrumentoAprovacao(instrumento, id) {
      const instrumentoExistente = this.form.instrumentos.find(
        (el) => el.relacionamentoId === id
      );

      if (instrumentoExistente) {
        instrumentoExistente.id = instrumento.value;
        instrumentoExistente.codigoTag = instrumento.text;
        return;
      }
      const participante = {
        id: this.form.solicitanteId?.value,
        nome: this.form.solicitanteId?.text,
      };
      this.form.instrumentos.push({
        ...instrumento,
        id: instrumento.value,
        codigoTag: instrumento.text,
        relacionamentoId: id,
        participante,
      });
    },
    associarDesassociarInstrumento() {
      this.$refs['modal-instrumento'].abrirModal(this.form);
    },
    abreModalAdicionaInstrumento(instrumentoIdClonar) {
      this.instrumentoClone = instrumentoIdClonar;
      this.$refs['modal-criacao-instrumento'].abrirModal(
        instrumentoIdClonar,
        this.form.solicitanteId
      );
    },
    associarInstrumentos(instrumentos) {
      instrumentos.forEach((instrumento) => {
        const instrumentoJaAdicionado = this.form.instrumentos.some(
          (i) => i.id === instrumento.id
        );

        if (instrumentoJaAdicionado) return;
        this.form.instrumentos.push({
          ...instrumento,
          id: instrumento.id,
          codigo: instrumento.codigo,
          participante: instrumento.participanteCliente,
          relacionamentoId: this.instrumentoClone,
          value: instrumento.id,
          text: instrumento.codigo,
        });
      });
      this.tabela.dados = this.form.instrumentos;
    },
    associarNovoInstrumento(instrumento) {
      if (!instrumento.id) return;
      const participante = {
        id: instrumento.participanteCliente?.value,
        nome: instrumento.participanteCliente?.text,
      };
      this.form.instrumentos.push({
        ...instrumento,
        id: instrumento.id,
        codigo: instrumento.codigoTag,
        participante,
        marca: instrumento.marca,
        modelo: instrumento.modeloInstrumento,
        relacionamentoId: this.instrumentoClone,
        value: instrumento.id,
        text: instrumento.codigoTag,
      });
      this.tabela.dados = this.form.instrumentos;
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.instrumentos.splice(
            this.form.instrumentos.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
<style>
.div-clonar-instrumento-ej2kdjiuw {
  display: flex;
  align-items: center;
  gap: 10px;
}
.div-clonar-instrumento-botao-ej2kdjiuw {
  text-transform: capitalize;
  padding: 3px !important;
  min-width: 0px !important;
  height: 20px !important;
}
</style>
